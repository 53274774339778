.main_sec {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.left {
  background-color: black;
  height: 100vh;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  width: 60%;
}

.img-style{
  margin-bottom:100px;
  width:70%
}
.main-img{
   size: cover;
}

@media only screen and (max-width: 500px) {
  .main_sec {
    display: block;
  }

  .left {
    height: 40vh;
  }

  .right {
    margin: 4rem 0;
  }
}
