/* AddAdmin and Edit Admin Css */
.grid_item{
    margin: 0 80px;
    
  }

.adminlisting{
  padding: 0 15px;
}

  @media (max-width:500px){
    .grid_item{
      margin: 0 20px;
      
    }
  }

  @media (max-width:700px){
    .grid_item{
      margin: 0 60px;
      
    }
  }

